import { Icon } from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";

interface Props {
  isLoading?: boolean;
}
export const RefreshIcon = ({ isLoading = false }: Props) => {
  return (
    <>
      <style jsx>{`
        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
        .rotating {
          animation: rotate 3s linear infinite;
        }
      `}</style>
      <Icon
        viewBox="0 0 16 15"
        className={isLoading ? "rotating" : ""}
      >
        <svg
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.1875 8.53125C13.7812 8.40625 13.375 8.625 13.25 9.03125C12.5938 11.375 10.4062 13 7.96875 13C5.875 13 4 11.8125 3.0625 10H6.21875C6.625 10 6.96875 9.6875 6.96875 9.25C6.96875 8.84375 6.65625 8.5 6.21875 8.5H1.21875C0.8125 8.5 0.46875 8.84375 0.46875 9.25V14.25C0.46875 14.6875 0.8125 15 1.21875 15C1.65625 15 1.96875 14.6875 1.96875 14.25V11.0625C3.21875 13.1562 5.5 14.5 7.96875 14.5C11.0625 14.5 13.8125 12.4375 14.6875 9.46875C14.8125 9.0625 14.5938 8.65625 14.1875 8.53125ZM14.75 0C14.3125 0 14 0.34375 14 0.75V3.96875C12.75 1.875 10.4688 0.5 8 0.5C4.90625 0.5 2.125 2.59375 1.25 5.5625C1.15625 5.9375 1.375 6.375 1.78125 6.46875C2.1875 6.59375 2.59375 6.375 2.6875 5.96875C3.375 3.65625 5.5625 2 8 2C10.0625 2 11.9375 3.21875 12.875 5H9.75C9.3125 5 9 5.34375 9 5.75C9 6.1875 9.3125 6.5 9.75 6.5H14.75C15.1562 6.5 15.5 6.1875 15.5 5.75V0.75C15.5 0.34375 15.1562 0 14.75 0Z"
            fill="currentColor"
          />
        </svg>
      </Icon>
    </>
  );
};
