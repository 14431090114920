import React, { memo, Dispatch, SetStateAction } from "react";

import { Center, Stack, VStack } from "@chakra-ui/layout";
import { useSession } from "next-auth/react";
import {
  Button,
  Text,
  Box,
  IconButton,
  useDisclosure,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Link,
  Flex,
  Badge,
  Wrap,
  WrapItem,
  Icon,
} from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import { Tooltip } from "@chakra-ui/tooltip";

import Masonry from "./masonry";
import theme from "@chakra-ui/theme";
import toast from "react-hot-toast";
import * as analytics from "../../utils/analytics";
import { BsCodeSlash } from "react-icons/bs";
import { BiCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TweetContext } from "../../context/tweetContext";
import { CloseIcon } from "@chakra-ui/icons";
import { getAccount } from "utils/sessionHelper";
import { layerStyle, radius, textStyle } from "theme/names";
import router from "next/router";
import { FiSettings } from "react-icons/fi";
import { useMiscellaneousContext } from "context/miscellaneousContext";
import { RefreshIcon } from "../icons/refresh";
const ShowTypes = ["masonry", "table"] as const;
type ShowType = (typeof ShowTypes)[number];

function isEqual(prevProps, nextProps) {
  if (
    prevProps.tweets != nextProps.tweets ||
    prevProps.savedTweet != nextProps.savedTweet ||
    prevProps.refresh != nextProps.refresh
  ) {
    return false;
  }
  return true;
}

const headers = [
  { label: "Handle", key: "user.screen_name" },
  { label: "Text", key: "full_text" },
];

interface Filter {
  id: string;
  key: string;
  name: string;
}

interface TweetsProps {
  sendRequest?: any;
  data?: any;
  setData?: any;
  tweets: any;
  savedTweet?: any;
  disableBlur?: boolean;
  disableBookmarkButton?: boolean;
  disableHideButton?: boolean;
  refresher?: any;
  refresh?: any;
  disableSaveCsv?: boolean;
  mode?: string;
  disableRequest?: boolean;
  disableEditTweet?: boolean;
  disableEmbed?: boolean;
  embedData?: any;
  disableTryAgain?: boolean;
  disableRefresh?: boolean;
  disableSettings?: boolean;
  refreshButton?: any;
  filters?: Filter[];
  handleRemoveFilter?: any;
  disableDescriptionWarning?: boolean;
  setExcludedUsers?: undefined | Dispatch<SetStateAction<string[]>>;
  masonryContainerDisplay?: string;
  isExportButtonSecondary?: boolean;
  refreshClickedCallback?: () => void;
  [key: string]: any;
}

const Tweets = ({
  sendRequest = undefined,
  data = undefined,
  setData = undefined,
  tweets,
  savedTweet,
  disableBlur = false,
  disableBookmarkButton = false,
  disableHideButton = false,
  refresher,
  refresh,
  disableSaveCsv = false,
  mode = "copy",
  disableRequest = false,
  disableEditTweet = false,
  disableEmbed = false,
  embedData = null,
  disableTryAgain = true,
  disableRefresh = true,
  disableSettings = true,
  refreshButton = undefined,
  filters = [],
  handleRemoveFilter = undefined,
  disableDescriptionWarning = true,
  setExcludedUsers = undefined,
  masonryContainerDisplay = "flex",
  isExportButtonSecondary = false,
  refreshClickedCallback = undefined,
  ...rest
}: TweetsProps) => {
  const [showAs, setShowAs] = React.useState<ShowType>("masonry");
  const { data: session, status } = useSession() ?? {};
  const loading = status === "loading";
  const [selectedTweet, setSelectedTweet] = React.useState(null);
  const [usedTweets, setUsedTweets] = React.useState([]);
  const csvLink = React.useRef(null);
  const miscellaneousContext: any = useMiscellaneousContext();

  const {
    isOpen: isOpenEmbed,
    onOpen: onOpenEmbed,
    onClose: onCloseEmbed,
  } = useDisclosure();
  const tweetContext: any = React.useContext(TweetContext);

  const removeTweet = (tw) => {
    console.log("removeTweet");
    if (data && setData != null) {
      console.log("removeTweet isData");
      let match = tweets.find((x) => x.id_str === tw.id_str);
      if (match) {
        console.log("removeTweet isMatch");
        tweets.splice(tweets.indexOf(match), 1);
        //@ts-ignore
        setData({ ...data, tweets: tweets });
        refresher({});
      }
    }
    console.log("end removeTweet");
  };

  React.useEffect(() => {
    setUsedTweets(
      session?.user?.data?.subscription?.isSubscribed ||
        disableBlur ||
        session?.user?.data?.remainingFreeSearch // > 0
        ? tweets
        : tweets.map((v, i) =>
          i >= 4
            ? {
              ...v,
              isBlurred: true,
              full_text:
                "Surprise code goes here!Surprise code goes here!Surprise code goes here!",
            }
            : { ...v }
        )
    );
  }, [session?.user?.data?.subscription?.isSubscribed, tweets]);

  var embedCode;
  if (embedData && !disableEmbed) {
    embedCode = `<div id="taplio"></div>
<script type='text/javascript' charset='utf-8'>     
  var iframe = document.createElement('iframe');document.getElementById('taplio').appendChild(iframe);
  iframe.id='iframe';iframe.src='https://app.taplio.com/embed?id=${
      //@ts-ignore
      embedData.id +
      (embedData.idList !== undefined
        ? "&idList=" +
        embedData.idList +
        "&list=" +
        encodeURIComponent(
          session?.user?.data?.lists.find((x) => x.id === embedData.idList)
            ?.name
        ) +
        "&handle=" +
        session?.user?.data?.twUserName
        : "")
      }';iframe.width='100%';iframe.height='2000px';iframe.frameBorder='0';iframe.scrolling='no';iframe.style='overflow: hidden;';
  var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
  var eventer = window[eventMethod];
  var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
  eventer(messageEvent, function(e) {
    if (isNaN(e.data)) return;
    document.getElementById('iframe').style.height = e.data + 'px';
  }, false);
</script>`;
  }

  return (
    <Box display={masonryContainerDisplay} justifyContent="center" w="100%">
      <Stack pb={10} w="100%" maxW="1000px">
        {!disableDescriptionWarning && !getAccount(session).description && (
          <Flex w="100%" justifyContent="center">
            <Link href="/onboarding">
              <Badge
                py="2px"
                px="7px"
                fontSize="sm"
                borderRadius="6"
                fontWeight="500"
                colorScheme="orange"
                variant="outline"
                textTransform="none"
              >
                Please provide more information so we can improve these results.
                Update settings here.
              </Badge>
            </Link>
          </Flex>
        )}
        <HStack
          display="flex"
          justifyContent="flex-end"
          pr={1}
          spacing={2}
          mb={3}
        >
          {!disableEmbed && (
            <Tooltip
              label={
                session?.user?.uid
                  ? "Embed posts on your website"
                  : "You need to be signed up to export CSV"
              }
            >
              <Box>
                <Button
                  isDisabled={!session?.user?.uid}
                  leftIcon={<BsCodeSlash />}
                  variant="primary"
                  onClick={() => {
                    onOpenEmbed();
                  }}
                >
                  Embed
                </Button>
              </Box>
            </Tooltip>
          )}
          {(!disableRefresh || !disableSettings) && (
            <Flex gap="3" mt="8">
              {!disableSettings && (
                <>
                  <Button
                    m={0}
                    isDisabled={!session?.user?.uid}
                    leftIcon={<FiSettings />}
                    variant="secondary"
                    borderColor="0A66C2"
                    onClick={() => {
                      router.push("/settings#search-settings");
                    }}
                  >
                    Settings
                  </Button>
                </>
              )}
              {!disableRefresh && (
                <Button
                  m={0}
                  isDisabled={
                    !session?.user?.uid ||
                    miscellaneousContext?.isDataRefreshing
                  }
                  sx={{
                    _disabled: {
                      bg: "white",
                      cursor: "not-allowed",
                    },
                  }}
                  leftIcon={
                    <RefreshIcon
                      isLoading={miscellaneousContext.isDataRefreshing}
                    />
                  }
                  variant="secondary"
                  borderColor="0A66C2"
                  onClick={async () => {
                    refreshClickedCallback && refreshClickedCallback();
                    //@ts-ignore
                    sendRequest && sendRequest(session);
                  }}
                >
                  {miscellaneousContext.isDataRefreshing
                    ? "Refreshing..."
                    : "Refresh"}
                </Button>
              )}
            </Flex>
          )}
          {!disableSaveCsv && (
            <Tooltip
              label={
                session?.user?.uid
                  ? "Export posts as CSV"
                  : "You need to be signed up to export CSV"
              }
            >
              <Box mt={disableSettings && disableRefresh ? 0 : 8}>
                <CSVLink
                  data={usedTweets}
                  headers={headers}
                  filename={"posts.csv"}
                  className="hidden"
                  ref={csvLink}
                  target="_blank"
                >
                  <Button
                    m={0}
                    // size="sm"
                    isDisabled={!session?.user?.uid}
                    leftIcon={
                      <Icon viewBox="0 0 16 17">
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14 10H12.3125L10.8125 11.5H14C14.25 11.5 14.5 11.75 14.5 12V14.5C14.5 14.7812 14.25 15 14 15H2C1.71875 15 1.5 14.7812 1.5 14.5V12C1.5 11.75 1.71875 11.5 2 11.5H5.15625L3.65625 10H2C0.875 10 0 10.9062 0 12V14.5C0 15.625 0.875 16.5 2 16.5H14C15.0938 16.5 16 15.625 16 14.5V12C16 10.9062 15.0938 10 14 10ZM13.5 13.25C13.5 12.8438 13.1562 12.5 12.75 12.5C12.3125 12.5 12 12.8438 12 13.25C12 13.6875 12.3125 14 12.75 14C13.1562 14 13.5 13.6875 13.5 13.25ZM7.46875 12.0312C7.59375 12.1875 7.78125 12.25 8 12.25C8.1875 12.25 8.375 12.1875 8.5 12.0312L12.75 7.78125C13.0625 7.5 13.0625 7.03125 12.75 6.75C12.4688 6.4375 12 6.4375 11.7188 6.75L8.75 9.71875V1.25C8.75 0.84375 8.40625 0.5 8 0.5C7.5625 0.5 7.25 0.84375 7.25 1.25V9.71875L4.25 6.71875C3.9375 6.4375 3.46875 6.4375 3.1875 6.71875C2.875 7.03125 2.875 7.5 3.1875 7.78125L7.46875 12.0312Z"
                            fill={isExportButtonSecondary ? "#0A66C2" : "white"}
                          />
                        </svg>
                      </Icon>
                    }
                    variant={isExportButtonSecondary ? "secondary" : "primary"}
                    borderColor={isExportButtonSecondary ? "0A66C2" : ""}
                  >
                    Export CSV
                  </Button>
                </CSVLink>
              </Box>
            </Tooltip>
          )}
        </HStack>

        {filters?.length > 0 && (
          <Center>
            <Wrap justify="center" pb="6" spacing="3" maxW="550px">
              {filters.map((filter, index) => (
                <WrapItem key={"filter" + index}>
                  <Button
                    key={filter.id}
                    variant="suggestion"
                    // @ts-ignore
                    onClick={() =>
                      handleRemoveFilter && handleRemoveFilter(filter.id)
                    }
                    display="flex"
                    alignItems="center"
                    py="2"
                    px="4"
                    borderRadius={radius.lg}
                    layerStyle={layerStyle.bgBorderGray}
                    textStyle={textStyle["body.medium.standard"]}
                  >
                    {filter.name}{" "}
                    {handleRemoveFilter && (
                      <CloseIcon
                        marginLeft="2"
                        width="0.75em"
                        height="0.75em"
                        // @ts-ignore
                        onClick={() => handleRemoveFilter(filter.id)}
                        cursor="pointer"
                      />
                    )}
                  </Button>
                </WrapItem>
              ))}
            </Wrap>
          </Center>
        )}

        <Masonry
          savedTweet={savedTweet}
          //@ts-ignore
          tweets={usedTweets}
          setExcludedUsers={setExcludedUsers}
          disableBookmarkButton={disableBookmarkButton}
          disableHideButton={disableHideButton}
          refresher={refresher}
          removeTweet={removeTweet}
          mode={mode}
          setSelectedTweet={setSelectedTweet}
          disableRequest={disableRequest}
          disableEditTweet={disableEditTweet}
          {...rest}
        />

        {tweets?.length >= 50 && !disableTryAgain && (
          <VStack as="form">
            <Text
              textStyle={textStyle["body.medium.light"]}
              color={theme.colors.gray[400]}
              fontSize="sm"
              textAlign="center"
              mt={10}
              pb={50}
            >
              We provide different results at each request, try launching it
              again
            </Text>
          </VStack>
        )}
        {!disableEmbed && embedData && (
          <Modal isOpen={isOpenEmbed} onClose={onCloseEmbed} size="lg">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Embed your collection</ModalHeader>
              <ModalCloseButton _hover={{ bg: "blue.100" }} />
              <ModalBody>
                <Box>
                  <Text textStyle={textStyle["body.medium.light"]}>
                    Include this code in your website where you want your
                    collection to be embedded.
                  </Text>
                  <Textarea
                    mt={5}
                    mb={5}
                    height="400px"
                    isDisabled
                    value={embedCode}
                  />
                  <CopyToClipboard
                    text={embedCode}
                    onCopy={() => {
                      toast.success("Post copied to clipboard", {
                        style: {
                          background: "gray.600",
                          color: "#222",
                        },
                      });
                    }}
                  >
                    <IconButton
                      position="absolute"
                      right="40px"
                      bottom="110px"
                      aria-label="copy-embed"
                      icon={<BiCopy fontSize="26px" />}
                      variant="ghost"
                      color="gray.600"
                      _hover={{ bg: "blue.100" }}
                    />
                  </CopyToClipboard>
                  <Text textStyle={textStyle["body.medium.light"]}>
                    Struggle with the integration?
                  </Text>
                  <Text textStyle={textStyle["body.medium.light"]} mb={5}>
                    Reach out to{" "}
                    <Link href="https://twitter.com/tibo_maker" target="_blank">
                      @tibo_maker
                    </Link>
                  </Text>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
      </Stack>
    </Box>
  );
};

export default memo(Tweets, isEqual);
