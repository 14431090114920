import React, { useEffect } from "react";
import { Box, Center } from "@chakra-ui/layout";
import { useRouter } from "next/dist/client/router";
import { useSession } from "next-auth/react";
import { CustomHead } from "components/custom-head";
import PuffLoader from "react-spinners/PuffLoader";

const Index = (props) => {

  const { data: session, status } = useSession() ?? {}
  const loading = status === "loading"


  const router = useRouter();

  useEffect(() => {
    if (typeof window !== "undefined" && !loading) {
      if (session?.user?.uid && session?.user?.data?.subscription?.isSubscribed) {
        router.push("/auto");
      }
      else if (session?.user?.uid) {
        router.push("/subscribe");
      }
      else {
        router.push("https://taplio.com" + window?.location?.search);
      }
    }
  },[session, loading]);

  // if (props.query) 
  //   console.log(props.query);

  let url = "https://taplio.com" + (props.query ? "?" + new URLSearchParams(props.query).toString() : "");
  // console.log("url: " + url);

  return (
    <Box w="100%" h="100vh">
      <CustomHead noIndex={true} />
      <Center position="absolute" top="50%" left="50%" transform="translateX(-50%)">
        <PuffLoader color={"#0A66C2"} loading={true} cssOverride={{display: "block", margin: "0 auto", borderColor: "red"}}  size={50} />
      </Center>
    </Box>
  );
};

Index.getInitialProps = async ({query}) => {

  let result = {
    query: query,
  }

  return result;
}

export default Index;
