import * as Sentry from "@sentry/nextjs";
import toast from "react-hot-toast";
import queryString from "query-string";
import { firebaseClient, getToken } from "../firebaseClient";
import { getAccount, updateUser } from "utils/sessionHelper";
import { ContactList } from "types/contactList";
import { isAllowed, useCredit } from "controllers/subscription";
import { CustomToast } from "components/toasts/CustomToast";
import * as analytics from 'utils/analytics';

export const getIcebreaker = async (
  session,
  selectedPeople,
  contacts,
  setContacts = undefined as any,
  data,
  setIcebreakers = undefined as any,
  mainContext,
  fromTable = false,
  columnRefresher,
  miscellaneousContext,
) => {

  if (!isAllowed(session?.user, "icebreaker")) {
    toast.error("You need to be subscribed to the Grow plan to access this feature");
    return;
  }

  const db = firebaseClient.firestore();

  let contact = selectedPeople[0];
  // console.log({contact});
  // console.log({data});

  const useCreditBoolean = await useCredit(session, mainContext, "creditsBestai", false)

  // start toast custom
  const toastIdCustom = toast.loading(<CustomToast useCreditBoolean={useCreditBoolean} />);

  const dataToSend: any = {
    idUser: getAccount(session)?.id,
    id: contact.id,
    description: encodeURIComponent(getAccount(session)?.description),
    useBestAi: useCreditBoolean
  };

  const url = queryString.stringifyUrl({
    url: "https://us-central1-ez4cast.cloudfunctions.net/linkedinContacts-icebreaker",
    query: dataToSend,
  });
  let fetchObject = fetch(url);
  const res = await fetchObject;
  const dataCall = await res.json();

  // end toast custom 
  toast.dismiss(toastIdCustom);

  if (dataCall?.success && dataCall?.icebreakers?.length) {

    let icebreaker = dataCall?.icebreakers[0];
    console.log("icebreaker: " + icebreaker);
    contact.icebreaker = icebreaker;

    try {
      await db.collection("users").doc(getAccount(session).id).collection("contacts").doc(contact.id).update({ icebreaker });
    }
    catch (e) {
      // console.log("Error in saving contact update: " + e.message, e);
      if (e.message?.toLowerCase()?.includes("no document to update")) {
        let newContact = { ...contact, icebreaker };
        delete newContact.actions;
        await db.collection("users").doc(getAccount(session).id).collection("contacts").doc(contact.id).set(newContact);
      }
    }

    columnRefresher({});

    let index = selectedPeople.indexOf(contact);
    if (setIcebreakers && setIcebreakers[index]) {
      setIcebreakers[index](icebreaker);
    }

    miscellaneousContext.setSidebarPerson({ ...contact });
    miscellaneousContext.setIsPersonSidebarOpen(true);

    analytics.log('contacts_icebreaker_generated', {
      userId: session?.user?.id,
      contactId: contact.id,
    });

    // // global refresh
    // if (setContacts) {
    //   selectedPeople.forEach(p => {
    //     let matchAllList = contacts.find((x) => p.id == x.id);
    //     if (matchAllList) {
    //       matchAllList.emails = p.emails;
    //     }
    //   })
    //   setContacts && setContacts([...contacts]);
    // }
  }
}

export const enrich = async (
  session,
  selectedPeople,
  contacts,
  setContacts = undefined as any,
  data,
  setEmails = undefined as any,
  mainContext,
  fromTable = false,
  columnRefresher,
  conf = {} as any,
) => {
  const db = firebaseClient.firestore();

  // console.log(selectedPeople);
  selectedPeople = selectedPeople.filter(x => !x.emails || x.emails.length === 0);
  if (!selectedPeople?.length) {
    toast.error("No email to look for");
    return;
  }
  // console.log(selectedPeople);

  if (selectedPeople.length > 10 && !getAccount(session).isAdmin) {
    toast.error("Can't search for more than 10 emails at once");
    return;
  }

  const dataToSend: any = {
    idUser: getAccount(session)?.id,
    ids: selectedPeople.map((p) => p.id).join(","),
  };

  // if (!isAllowed(session?.user, "enrich")) {
  //   mainContext.onOpenUpgrade();
  //   return;
  // }

  if (getAccount(session).creditsEmail === undefined) {
    await updateUser(session, { creditsEmail: 100 });
  }

  if (getAccount(session).creditsEmail < selectedPeople.length && !getAccount(session).isAdmin) {
    toast.error("Not enough email credits, wait for reset or contact us to get more.");
    return;
  }

  // selectedPeople.map((x) => x.emails = ["toto"]);
  // console.log(contacts);
  // contacts[0].emails = ["toto"];
  // console.log({data});
  // console.log({contacts});
  // console.log({selectedPeople});
  // setContacts([...contacts]);
  // contacts[0].emails = contact.emails;
  // return;

  const updateContacts = new Promise(async (resolve, reject) => {
    try {
      const url = queryString.stringifyUrl({
        url: "https://us-central1-ez4cast.cloudfunctions.net/linkedinContacts-enrich",
        query: dataToSend,
      });
      let fetchObject = fetch(url);

      if (selectedPeople.length <= 10) {

        const res = await fetchObject;
        const data = await res.json();

        if (data?.success) {
          let nbEmailFound = 0;
          for (const contact of selectedPeople) {

            let match = data.contacts.find((x) => contact.id == x.contact.id);
            let dataToSave = {} as any;
            if (match) {
              contact.emails = match.contact.emails;
              dataToSave = { ...match.contact };
            }

            if (!dataToSave?.emails?.length) {
              contact.emails = ["Not found"];
              dataToSave.emails = ["Not found"];
              dataToSave.noEmailFound = true;
            }
            else {
              nbEmailFound += contact.emails.length;
            }

            console.log(dataToSave);
            try {
              contacts.lists = [];
              await db.collection("users").doc(getAccount(session).id).collection("contacts").doc(contact.id).update(dataToSave);
            }
            catch (e) {
              // console.log("Error in saving contact update: " + e.message, e);
              if (e.message?.toLowerCase()?.includes("no document to update")) {
                let newContact = { ...contact, ...dataToSave };
                delete newContact.actions;
                await db.collection("users").doc(getAccount(session).id).collection("contacts").doc(contact.id).set(newContact);
              }
            }

            let index = selectedPeople.indexOf(contact);
            if (setEmails && setEmails[index]) {
              console.log("set email to: " + dataToSave.emails[0]);
              setEmails[index](dataToSave.emails[0])
            }
          }

          columnRefresher({});

          if (nbEmailFound > 0) {
            toast.success(fromTable ? (nbEmailFound + " emails found") : "Email found!");
            await updateUser(session, { creditsEmail: getAccount(session).creditsEmail - nbEmailFound });

            // global refresh
            if (setContacts) {
              selectedPeople.forEach(p => {
                let matchAllList = contacts.find((x) => p.id == x.id);
                if (matchAllList) {
                  matchAllList.emails = p.emails;
                }
              })
              setContacts && setContacts([...contacts]);
            }
            resolve(data?.contacts);
          }
          else
            reject("No email found :(");
        }
        else reject("No email found :(");
      }
      else {
        toast.success("Currently getting emails, please come back in a few minutes");
        resolve({});
      }


    } catch (e) {
      console.log("Error in searching emails: " + e.message, e);
      toast.error("Error in searching emails - try with a smaller batch");
      Sentry.captureException(e);
      reject("An error happened");
    }
  })

  if (fromTable) {
    return updateContacts
      .then(data => {
        toast.success("Contact updated")
      })
      .catch(err => {
        toast.error(err);
      })
  } else {
    await toast.promise(
      updateContacts,
      {
        loading: "loading ... ",
        success: "Some emails has been found",
        error: "No email found :(",
      }
    );
  }
};

export const searchPeople = async (session, dataToSend) => {
  try {

    dataToSend.plan = getAccount(session)?.subscription?.plan;

    const url = queryString.stringifyUrl({
      url: "https://us-central1-ez4cast.cloudfunctions.net/linkedinContacts-get",
      query: { ...dataToSend, isInfluentLeader: true },
    });
    const token = await getToken(session, "searchPeople")
    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        tokenuserid: session.user.uid,
      }
    });
    const data = await res.json();

    if (!data?.success && data?.error == "Request failed with status code 500") {
      // await updateUser(session, {idLinkedin: ""});
      toast.error("Wrong paramaeters - Maybe Linkedin cookies are outdated - please get to the Taplio linkedin extension and connect again");
    }

    analytics.log('contacts_search_people', {
      userId: session?.user?.id,
      searchTerm: dataToSend.searchTerm,
    });

    return data;
  } catch (e) {
    console.log("Error in searching users: " + e.message, e);
    // toast.error("Error in searching users: " + e.message);
    Sentry.captureException(e);
  }
};

export const addRemovePeopleToList = async (
  idUser: string,
  listId: number,
  peopleList: any[],
  toggle: "save" | "remove",
  setDataLoaderAdd?: Function,
  refetchAll: boolean = false,
) => {

  if (!idUser) {
    throw new Error("Invalid idUser");
  }

  // console.log('peopleList:', peopleList)
  const db = firebaseClient.firestore();

  let countDone = 0;
  setDataLoaderAdd && setDataLoaderAdd({ total: peopleList.length, done: 0 });

  // if need to refresh all
  if (refetchAll) {
    let res = await fetch("https://us-central1-ez4cast.cloudfunctions.net/linkedinContacts-get?from=list&people=" + peopleList.map(p => p.username).join(","));
    let data = await res.json();
    if (data?.contacts) {
      peopleList = data.contacts;
    }
  }

  for (const people of peopleList) {

    let p = { ...people };
    delete p.actions;

    const contactDoc = await db
      .collection("users")
      .doc(idUser)
      .collection("contacts")
      .doc(p.id)
      .get();

    if (!contactDoc.exists) {
      p.lists = [listId];
      p.created_at = new Date();
      await db
        .collection("users")
        .doc(idUser)
        .collection("contacts")
        .doc(p.id)
        .set(p);
    } else {
      let data: any = contactDoc.data();

      if (toggle === "save") {
        if (data.lists) {
          data.lists = data.lists.concat([listId]);
        }
      } else {
        data.lists = data.lists.filter((l) => l !== listId);
      }

      if (data.lists.length > 0) {
        await db
          .collection("users")
          .doc(idUser)
          .collection("contacts")
          .doc(p.id)
          .update({ lists: data.lists });
      } else {
        await db
          .collection("users")
          .doc(idUser)
          .collection("contacts")
          .doc(p.id)
          // .delete();
          .update({ deleted: true, lists: [], deletedAt: new Date() });
      }
    }

    analytics.log('contacts_add_remove_people_to_list', {
      userId: idUser,
      contactId: p.id ?? 0,
      listId: listId,
      toggle: toggle,
    });

    countDone++;
    // console.log('countDone:', countDone);
    setDataLoaderAdd && setDataLoaderAdd({ total: peopleList.length, done: countDone });
  }
};

export const deleteList = async (session, listId: number) => {
  const idUser = getAccount(session)?.id;
  const lists = getAccount(session)?.contactLists ? [...getAccount(session)?.contactLists] : [];
  const selectedList = lists.find((x) => x.id === listId);

  lists.splice(lists.indexOf(selectedList), 1);
  await updateUser(session, { contactLists: lists });

  const db = firebaseClient.firestore();
  const contactDocs = await db
    .collection("users")
    .doc(idUser)
    .collection("contacts")
    .where("lists", "array-contains", listId)
    .get();

  const contacts: any[] = [];
  contactDocs.forEach((c) => {
    contacts.push(c.data());
  });

  for (const c of contacts) {
    c.lists.forEach((l, index) => {
      if (l === listId) {
        c.lists.splice(index, 1);
      }
    });

    if (c.lists.length > 0) {
      await db
        .collection("users")
        .doc(idUser)
        .collection("contacts")
        .doc(c.id)
        .update({ lists: c.lists });
    } else {
      await db
        .collection("users")
        .doc(idUser)
        .collection("contacts")
        .doc(c.id)
        // .delete();
        .update({ deleted: true, lists: [], deletedAt: new Date() });
    }
  }

  analytics.log('contacts_delete_list', {
    userId: idUser,
    listId: listId,
  });
};

export const getContacts = async (idUser: string, listId: number, limit = -1, pageNo = -1, paginationCursor: any = null) => {
  let contacts: ContactList[] = [];
  try {
    const db = firebaseClient.firestore();

    let query = db
      .collection("users")
      .doc(idUser)
      .collection("contacts")
      .orderBy("username")
      .where("lists", "array-contains", listId);

    // console.log('getContacts with limit: ', limit);

    if (limit > 0) {
      query = query.limit(limit);
    }

    if ((pageNo - 1) > 0 && Boolean(paginationCursor?.current?.size) === true) {
      query = query.startAt(paginationCursor.current.get((pageNo - 1)));
    }

    const contactQueryDocs = await query.get();

    if (Boolean(paginationCursor?.current) === true) {
      const lastVisible = contactQueryDocs.docs[contactQueryDocs.docs.length - 1];
      paginationCursor.current.set(pageNo, lastVisible);
    }

    contactQueryDocs.forEach((doc) => {
      const currentDocument = doc.data() as ContactList;
      contacts.push(currentDocument);
    });

    return contacts;
  } catch (err) {
    console.log("Error in fetching contacts: ", err);
    toast.error("Error in fetching contacts: " + err.message);
  }

  return contacts;
};

export const countContacts = async (idUser: string, listId: number) => {
  if (!idUser || (!listId && listId !== 0)) return 0;
  let contactCounts = 0;
  try {
    const db = firebaseClient.firestore();

    let query = db
      .collection("users")
      .doc(idUser)
      .collection("contacts")
      .orderBy("username")
      .where("lists", "array-contains", listId)
      ;
    contactCounts = (await query.get()).size || 0;

  } catch (err) {
    console.log("Error in fetching contact counts: ", err);
    toast.error("Error in fetching contacts counts " + err.message);
  }
  return contactCounts;

}

export const moveContacts = async (
  idUser: string,
  contacts: any[],
  currentListId: number,
  newListId: number,
  setLoader?: Function
) => {
  try {
    if (!idUser) {
      throw new Error("Invalid idUser");
    }

    const db = firebaseClient.firestore();
    let countDone = 0;
    setLoader && setLoader({ total: contacts.length, done: 0 });

    for (const contact of contacts) {
      let newList = [...contact?.lists].filter((l) => l !== currentListId);
      newList.push(newListId);

      await db
        .collection("users")
        .doc(idUser)
        .collection("contacts")
        .doc(contact?.id)
        .update({ lists: newList });

      countDone++;
      setLoader && setLoader({ total: contacts.length, done: countDone });
    }

    analytics.log('contacts_move_contacts', {
      userId: idUser,
      currentListId: currentListId,
      newListId: newListId,
    });

  } catch (err) {
    console.log("Error happened in moving contacts: ", err);
    toast.error("Error happened in moving contacts: " + err);
  }
};

export const getLikersAndCommenters = async (idUser: string, listLimit = -1, planLimit = 10) => {
  let contacts: ContactList[] = [];

  try {
    const db = firebaseClient.firestore();

    const queryContactLists = await db
      .collection("users")
      .doc(idUser).get();

    const contactListsData: any = queryContactLists?.data()?.contactLists || [];

    let nbListLikerCommenter: any = [];

    contactListsData.forEach((list: any) => {
      if (list?.isLikers || list?.isRepliers) nbListLikerCommenter.push(list?.id);
    });

    let query = db
      .collection("users")
      .doc(idUser)
      .collection("contacts")
      .where("lists", "array-contains-any", nbListLikerCommenter);

    // console.log('getContacts with listLimit: ', listLimit);

    if (listLimit > 0) {
      query = query.limit(listLimit);
    }

    const contactQueryDocs = await query.get();


    contactQueryDocs.forEach((doc) => {
      const currentDocument = doc.data() as ContactList;
      contacts.push(currentDocument);
    });

    //only fetch contacts that have interacted with user - manoj
    contacts = contacts.filter(
      (c: any) => {
        return c?.lastInteractionDate
      });

    //cannot use orderBy in query because of indexing -- manoj
    contacts.sort(
      (a: any, b: any) => b?.lastInteractionDate?.toDate()?.getTime() - a.lastInteractionDate.toDate()?.getTime()
    );

    //use planLimit to slice for planType
    contacts = contacts.slice(0, planLimit);

    //@ts-ignore
    // contacts.forEach(c => console.log(c?.lastInteractionDate.toDate(), 'check interaction date'));

    return contacts;
  } catch (err) {
    console.log("Error in fetching contacts: ", err);
    toast.error("Error in fetching contacts: " + err.message);
  }

  return contacts;
};