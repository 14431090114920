import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { Box, Divider, Flex, Stack, Text } from "@chakra-ui/layout";
import {
  useColorModeValue,
  Textarea,
  Spinner,
  Select,
  Link,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import theme from "@chakra-ui/theme";
import { Button, IconButton } from "@chakra-ui/button";
import { FaRetweet, FaComment } from "react-icons/fa";
import { Icon, ViewOffIcon, CloseIcon } from "@chakra-ui/icons";
import { format, formatDistance } from "date-fns";
import numbro from "numbro";
import styled from "@emotion/styled";
import twitter from "twitter-text";
import { Image } from "@chakra-ui/image";
import { hideTweet, refreshProfilePic } from "../../../controllers/bookmark";
import { getAggreeDisaggree } from "../../../controllers/search";
import { generate } from "../../../controllers/variations";
import { FiCopy, FiMoreHorizontal, FiUser } from "react-icons/fi";
import { CgDanger } from "react-icons/cg";
import { RiCloseFill } from "react-icons/ri";
import { AiFillEye, AiOutlineMinusCircle } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";
import { SiGoogletranslate } from "react-icons/si";
import { scheduleTweet, checkTweetValid } from "../../../controllers/schedule";
import { v4 as uuidv4 } from "uuid";
import { BiCopy } from "react-icons/bi";
import UserPopup from "../user-popup";
import { useClipboard } from "@chakra-ui/hooks";
import { useSession } from "next-auth/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import * as analytics from "../../../utils/analytics";
import { postData, useOnClickOutside } from "../../../utils/helpers";
import { CollectionDropdown } from "../../collection-dropdown";
import { firebaseClient, getToken } from "../../../firebaseClient";
import { TweetContext } from "../../../context/tweetContext";
import { MainContext } from "../../../context/mainContext";
import autosize from "autosize";
import { HiOutlineLightningBolt } from "react-icons/hi";
import { getAccount } from "../../../utils/sessionHelper";
import { isAllowed } from "controllers/subscription";
import { useOnboardingSteps } from "components/onboard-panel/useOnboardingSteps";
import { useFirebaseUser } from "../../../utils/useFirebaseUser";
import { ImageNextChakra } from "components/common/image-next-chakra";
import { color, textStyle, variant } from "theme/names";
import { colors } from "theme/foundations/text";
import { ConfirmPopup } from "components/popups/confirmPopup";
import { colors as labelColors } from "components/tweet-labels/index";

const StyledText = styled(Text)`
  a {
    color: #0a66c2;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`;

const Tweet = ({
  tweet,
  savedTweet = [],
  disableBookmarkButton,
  disableHideButton,
  disableMainHideButton = true,
  refresher = undefined,
  removeTweet = undefined,
  mode = "copy",
  source = "unknown",
  requestVariation = undefined,
  setSelectedTweet = undefined,
  disableRequest,
  isVariation = false,
  disableHeader = false,
  disableFooter = false,
  disableEditTweet = false,
  disableSubMenu = false,
  disableName = true,
  disableCopy = false,
  disableRetweet = true,
  disableLike = false,
  disableIntro = true,
  disableFollowersCount = true,
  isSelectable = false,
  disableCheckmark = true,
  disableUserPopup = false,
  toggleSelection = undefined,
  disableAddToQueue = true,
  disableHideGeneration = true,
  disableAggreeDisagree = true,
  disableSeeOn = true,
  disableTweetSelection = false,
  disableTranslate = false,
  forceSelectedTweet = undefined as any,
  addToComposerBtnText = "",
  showCopyToClipboard = false,
  showWarningOnAddToComposer = false,
  displayVariationLabel = false,
  setExcludedUsers = undefined as
  | undefined
  | Dispatch<SetStateAction<string[]>>,
}) => {
  const { hasCopied, onCopy } = useClipboard(
    // tweet.data.full_text + `\n\nInspired by @${tweet.data.twUserName}`
    tweet.data.full_text
  );
  const { data: session, status } = useSession() ?? {}
  const loading = status === "loading"
  const isAuthenticated = useFirebaseUser();
  const [isSaved, setIsSaved] = React.useState(false);
  // const [isSelectedInternal, setIsSelectedInternal] = React.useState(false);
  const [isSelected, setIsSelected] = React.useState(false);
  //@ts-ignore
  const inLists = [
    ...new Set(
      ...savedTweet
        // @ts-ignore
        .filter((x) => x.id_str === tweet.data.id_str)
        // @ts-ignore
        .map((s) => s.lists),
      tweet.data.lists
    ),
  ];
  const initRef = React.useRef(null);

  const [editValue, setEditValue] = React.useState("");

  const [isOpenSubMenu, setIsOpenSubMenu] = React.useState(false);
  const refSubMenu = React.useRef<any>();
  useOnClickOutside(refSubMenu, async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    setIsOpenSubMenu(false);
  });

  const [isOpenBookmark, setIsOpenBookmark] = React.useState(false);
  const refBookmark = React.useRef<any>();
  useOnClickOutside(refBookmark, async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    setIsOpenBookmark(false);
  });

  const [isOpenUserProfile, setIsOpenUserProfile] = React.useState(false);
  const refUserProfile = React.useRef<any>();
  useOnClickOutside(refUserProfile, async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    setIsOpenUserProfile(false);
  });

  const [isOpenPopOver, setIsOpenPopOver] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isLoadingVariation, setIsLoadingVariation] = React.useState(false);
  const [isLoadingAggree, setIsLoadingAggree] = React.useState(false);
  const [nbChar, setNbChar] = React.useState<number>(0);
  const [typingTimeout, setTypingTimeout] = React.useState<any>(undefined);

  const [languageTrans, setLanguageTrans] = React.useState<any>("fr");
  const [isLoadingTrans, setIsLoadingTrans] = React.useState<any>(false);
  const [hasInit, setHasInit] = React.useState(false);

  const [addedToQueue, setAddedToQueue] = React.useState(false);
  const [isLoadingAddToQueue, setIsLoadingAddToQueue] = React.useState(false);

  const openPopOver = () => setIsOpenPopOver(true);
  const closePopOver = () => setIsOpenPopOver(false);
  const tweetContext = React.useContext<any>(TweetContext);
  const mainContext = React.useContext<any>(MainContext);
  const { updateOnboardSteps } = useOnboardingSteps();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const lightGray = useColorModeValue("gray.200", "gray.600");
  const gray600 = useColorModeValue("gray.600", "gray.100");
  const gray500 = useColorModeValue("gray.500", "gray.300");
  const white = useColorModeValue("white", "gray.800");

  useEffect(() => {
    if (!hasInit && session?.user?.data) {
      setLanguageTrans(session.user.data.language ?? "fr");
      setHasInit(true);
    }
  }, [isOpenSubMenu]);

  useEffect(() => {
    const isSaved =
      //@ts-ignore
      savedTweet.map((tw) => tw.id_str).includes(tweet.data.id_str) ||
      tweet?.data?.lists?.length;
    setIsSaved(isSaved);
  }, [savedTweet]);

  const handleAddToComposer = () => {
    if (
      showWarningOnAddToComposer &&
      tweetContext?.refComposer?.current?.textState()?.text
    ) {
      onOpen();
      return;
    }
    analytics.log('industry_news_article_clicked');
    addTextToComposer();
  };

  const addTextToComposer = () => {

    // console.log("hit_edit_and_tweet");
    // console.log("tweet.data.fullTextAppended: " + tweet.data.fullTextAppended);

    tweet.data.isVariation = isVariation;

    //@ts-ignore
    tweetContext.newTweet({ text: tweet.data.fullTextAppended });
    // @ts-ignore
    tweetContext.setIsTweetTextChanged(true);
    if (!disableTweetSelection) {
      //@ts-ignore
      tweetContext.setSelectedTweet(tweet.data);
    }
    //@ts-ignore
    tweetContext.open();
  };

  const handleOnClickExcludeUser = () => {
    if (!setExcludedUsers) {
      return;
    }

    toast.success(`${tweet.data.twUserName} excluded from the feed`);
    setExcludedUsers((prevExcludedUsers) => {
      if (prevExcludedUsers.includes(tweet.data.twUserName)) {
        return prevExcludedUsers;
      }
      return [...prevExcludedUsers, tweet.data.twUserName];
    });
  };

  // console.log({inLists});
  // entities = JSON.parse(tweet.data.entities),
  // entities = JSON.parse(tweet.data.entities),
  // tweet.data.extended_entities
  // if (tweet.data.full_text.includes("my first billboard")) {
  //   // console.log(tweet.data);
  //   // console.log(typeof tweet.data.user.entities);
  //   console.log(tweet.data.extended_entities);
  //   console.log(tweet.data.entities);
  //   console.log(tweet.data?.entities?.url?.urls);
  // }

  // if (tweet?.data?.user?.entities && typeof tweet.data.user.entities == "string")
  //   tweet.data.user.entities = JSON.parse(tweet.data.user.entities);

  if (!tweet.data.fullText) {
    tweet.data.fullText = twitter.autoLink(tweet.data.full_text, {
      //@ts-ignore
      // urlEntities: tweet.data?.entities?.url?.urls,
      urlEntities: tweet.data?.extended_entities?.urls,
      //@ts-ignore
      mentionEntities: tweet.data?.user_mentions,
      usernameIncludeSymbol: true,
      targetBlank: true,
    });
  }

  if (tweet?.data?.created_at?.toDate) {
    tweet.data.created_at = tweet.data.created_at.toDate();
    // Sentry.captureException(new Error("Invalid created_at"));
  }

  if (!tweet.data.fullTextAppended) {
    // tweet.data.fullTextAppended = isVariation ? tweet.data.full_text : tweet.data.full_text + "\n\nInspired by @" + tweet.data.user?.screen_name;
    tweet.data.fullTextAppended = tweet.data.full_text;
  }

  const profileLink =
    tweet?.data?.user?.customProfileLink ??
    "https://twitter.com/" + tweet.data.user?.screen_name;
  // console.log("profileLink: " + profileLink);
  // console.log(tweet?.data);

  // console.log("render tweet");
  // let isSelected = isSelectedInternal && (!forceSelectedTweet || forceSelectedTweet.id_str === tweet.data.id_str) ;
  // console.log(forceSelectedTweet);

  const userPhotoAndName = () => {
    const firstName = tweet.data.user?.firstName;
    const lastName = tweet.data.user?.lastName;
    const userIntro = tweet.data.user.description || "";

    return (
      <Box display="flex">
        <ImageNextChakra
          key={tweet.data.user.profile_image_url_https}
          w={"32px"}
          h={"32px"}
          borderRadius={20}
          overflow="hidden"
          ml={0.5}
          //@ts-ignore
          src={tweet.data.user.profile_image_url_https}
          alt={"Profile picture of " + tweet.data.user.name}
          onClick={() => {
            if (!disableUserPopup) setIsOpenUserProfile(!isOpenUserProfile);
          }}
          isSwitchToAvatar={true}
          unoptimized={"true"}
          avatarProps={{
            h: 8,
            w: 8,
            name:
              !firstName || !lastName
                ? tweet.data.user.name
                : `${firstName} ${lastName}`,
          }}
        />
        {(!disableName || !disableIntro) && (
          <Flex ml={3} flexDirection="column" justifyContent="center">
            {!disableName &&
              <>
                <Text
                  textStyle={textStyle["body.medium.light"]}
                  fontSize={12}
                  // color={theme.colors.gray[500]}
                  fontWeight="600"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  width="180px"
                >
                  {tweet.data.user.name}
                </Text>
                {tweet?.data?.user?.screen_name && (
                  <Text
                    textStyle={textStyle["body.medium.light"]}
                    as="a"
                    href={profileLink}
                    target="_blank"
                    fontSize={11}
                    color={theme.colors.gray[400]}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    width="90px"
                  >
                    {"@" + tweet.data.user.screen_name}
                  </Text>
                )}
              </>
            }
            {
              (
                (!disableIntro && userIntro) &&
                <Text maxW={"90px"} textStyle={textStyle["body.medium.light"]} as="a" href={profileLink} target="_blank" fontSize={11} color={theme.colors.gray[400]} overflow="hidden" textOverflow="ellipsis" whiteSpace={"nowrap"} title={userIntro}>
                  {userIntro}
                </Text>
              )
            }
          </Flex>
        )}
      </Box>
    );
  };

  const handleVariations = async () => {

    updateOnboardSteps("aiWriting", "schedule");
    // //@ts-ignore
    // tweetContext.requestVariation(tweet.data);

    if (
      session?.user?.data?.skipPopupVariationAuto &&
      (session?.user?.data?.creditsAuto ||
        isAllowed(session.user, "ai_variation"))
    ) {
      setIsLoadingVariation(true);
      let result = await generate(
        session,
        tweet.data,
        1,
        getAccount(session)?.tempLevel,
        mainContext
      );
      setIsLoadingVariation(false);

      if (result?.success && result?.data?.variations?.length) {
        let newVariation = result?.data?.variations[0];
        //@ts-ignore
        tweetContext.newTweet({ text: newVariation });
        // @ts-ignore
        tweetContext.setIsTweetTextChanged(true);
        //@ts-ignore
        tweetContext.open();
        toast.success("Variation pasted in composer");
      }
    } else {
      //@ts-ignore
      tweetContext.requestVariationAuto(tweet.data);
    }
  };

  const [hoverVariations, setHoverVariations] = useState(false);
  const [hoverCollections, setHoverCollections] = useState(false);
  const [collectionLoading, setCollectionLoading] = useState(false);

  return (
    <Box
      key={tweet.data.id}
      minW="250px"
      // bg="red.50"
      layerStyle="bgBorderRoundedHoverShadow"
      textStyle="body.medium.standard"
      onClick={() => {
        if (isSelectable || toggleSelection) {
          console.log("click tweet");
          // setIsSelectedInternal(!isSelectedInternal);
          if (isSelectable) setIsSelected(!isSelected);
          //@ts-ignore
          toggleSelection && toggleSelection(tweet.data);
        }
      }}
      _hover={
        isSelectable || toggleSelection
          ? {
            backgroundColor: "gray.100",
            boxShadow:
              "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)",
            borderColor: "transparent",
          }
          : {
            boxShadow:
              "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)",
            borderColor: "transparent",
          }
      }
      cursor={isSelectable || toggleSelection ? "pointer" : "unset"}
    >
      {tweet?.data?.variationType && displayVariationLabel && (
        <Box
          position={"absolute"}
          top="10px"
          right="10px"
          bg={`${labelColors[Math.floor(Math.random() * labelColors.length)]}.600`}

          rounded={"md"}
          padding="1px 10px"
          fontSize={"sm"}
          color={"white"}
        >
          {tweet.data.variationType}
        </Box>
      )
      }
      <Box css={{ filter: tweet.data.isBlurred && "blur(3px)" }}>
        {!disableHeader && (
          <>
            <Flex p={2} alignItems="center" justifyContent="space-between">
              {isVariation ? (
                <Box display="flex" justifyContent="space-between" w="100%">
                  <Box display="flex">{userPhotoAndName()}</Box>
                  {!disableHideGeneration && (
                    <IconButton
                      aria-label="delete"
                      color="twitter.600"
                      variant="outline"
                      size="sm"
                      onClick={async () => {
                        //@ts-ignore
                        removeTweet && removeTweet(tweet.data);
                      }}
                      icon={<Icon color={gray600} as={RiCloseFill} />}
                      mr={0}
                    />
                  )}
                </Box>
              ) : (
                <>
                  <Stack isInline alignItems="center">
                    {!disableUserPopup ? (
                      <Box position="relative">
                        {userPhotoAndName()}
                        {isOpenUserProfile && (
                          <Box
                            position="absolute"
                            top="40px"
                            left="0"
                            w="280px"
                            bg={white}
                            boxShadow="rgba(100, 100, 111, 0.15) 0px 2px 19px 0px;"
                            rounded="md"
                            zIndex="1"
                            ref={refUserProfile}
                          >
                            <UserPopup
                              user={tweet.data.user}
                              tweet={tweet.data}
                            />
                          </Box>
                        )}
                      </Box>
                    ) : (
                      userPhotoAndName()
                    )}
                    {mode == "answer" ? (
                      <Flex maxWidth="100px" alignItems="center">
                        <Text
                          textStyle={textStyle["body.medium.light"]}
                          mt={1}
                          ml={2}
                          mr={2}
                          fontSize="md"
                        >
                          {numbro(tweet.data.user.followers_count)
                            ?.format({
                              // mantissa: 1,
                              average: true,
                              // totalLength: 3,
                            })
                            .replace(".0", "")}
                        </Text>
                        <Text
                          textStyle={textStyle["body.medium.light"]}
                          mt={2}
                          fontSize="11px"
                          color="gray.400"
                        >
                          Followers
                        </Text>
                      </Flex>
                    ) : (
                      <></>
                    )}
                  </Stack>
                  <Stack isInline alignItems="center" justifyContent="center">
                    {tweet.data.type != "linkedin" ? (
                      <Link
                        variant={variant.Link.light}
                        noOfLines={1}
                        wordBreak={"break-all"}
                        maxWidth="110px"
                        fontSize={12}
                        // color={theme.colors.gray[400]}
                        mt={1}
                        mr={1}
                        href={`https://twitter.com/${tweet.data.twUserName}/status/${tweet.data.id_str}`}
                        target="_blank"
                      >
                        {
                          //@ts-ignore
                          // format(new Date(tweet.data.created_at), "Pp")
                          mode == "answer"
                            ? formatDistance(
                              new Date(tweet.data.created_at),
                              new Date(),
                              { addSuffix: true }
                            )
                            : format(
                              new Date(tweet.data.created_at),
                              "yyyy/MM/dd"
                            )
                        }
                      </Link>
                    ) : (
                      <Text
                        textStyle={textStyle["body.medium.light"]}
                        noOfLines={1}
                        wordBreak={"break-all"}
                        maxWidth="110px"
                        fontSize={12}
                        color={theme.colors.gray[400]}
                        mt={1}
                        mr={1}
                      >
                        {
                          //@ts-ignore
                          // format(new Date(tweet.data.created_at), "Pp")
                          mode == "answer"
                            ? formatDistance(
                              new Date(tweet.data.created_at),
                              new Date(),
                              { addSuffix: true }
                            )
                            : format(
                              new Date(tweet.data.created_at),
                              "yyyy/MM/dd"
                            )
                        }
                      </Text>
                    )}
                    {!disableRequest && (
                      <Flex
                        {...{
                          onMouseEnter: () => {
                            setHoverVariations(true);
                          },
                          onMouseLeave: () => {
                            setHoverVariations(false);
                          },
                          onBlur: () => {
                            setHoverVariations(false);
                          },
                          borderRadius: "10px",
                        }}
                      >
                        <IconButton
                          aria-label="AI Variation"
                          variant={variant.Button.secondaryAI}
                          size="sm"
                          isLoading={isLoadingVariation}
                          onClick={handleVariations}
                          cursor="pointer"
                          icon={
                            <Box
                              {...{
                                opacity: hoverVariations ? 1 : 0.65,
                              }}
                            >
                              <Icon viewBox="0 0 13 16">
                                <defs>
                                  <linearGradient
                                    id="Gradient"
                                    x1="0"
                                    x2="0"
                                    y1="0"
                                    y2="1"
                                  >
                                    <stop offset="20%" stopColor={"#e86d8a"} />
                                    <stop offset="80%" stopColor={"#527ff0"} />
                                  </linearGradient>
                                </defs>
                                <path
                                  d="M9.69791 0.03125C10.2917 0.03125 10.7292 0.59375 10.5729 1.15625L8.88541 7H11.5729C12.1667 7 12.6667 7.5 12.6667 8.09375C12.6667 8.4375 12.5104 8.71875 12.2604 8.9375L4.16666 15.8125C4.01041 15.9375 3.82291 16 3.60416 16C3.01041 16 2.57291 15.4375 2.72916 14.875L4.41666 9H1.69791C1.13541 9 0.666656 8.53125 0.666656 7.96875C0.666656 7.65625 0.791656 7.34375 1.01041 7.15625L9.13541 0.21875C9.29166 0.09375 9.47916 0 9.69791 0V0.03125ZM8.57291 2.65625L2.91666 7.5H5.41666C5.63541 7.5 5.85416 7.625 6.01041 7.8125C6.13541 8 6.19791 8.25 6.13541 8.46875L4.72916 13.375L10.4479 8.5H7.91666C7.66666 8.5 7.44791 8.40625 7.29166 8.21875C7.16666 8.03125 7.10416 7.78125 7.16666 7.5625L8.57291 2.65625Z"
                                  fill="url(#Gradient)"
                                />
                              </Icon>
                            </Box>
                          }
                          mr={0}
                        />
                      </Flex>
                    )}
                    {/* <Tooltip label={isSaved ? "Add/remove this tweet from collections" : "Save tweet for later access."}> */}
                    <Box>
                      {!disableBookmarkButton && (
                        <CollectionDropdown
                          inLists={inLists}
                          refresher={refresher}
                          tweet={tweet.data}
                          isOpenPopOver={isOpenPopOver}
                          closePopOver={closePopOver}
                          setIsSaved={setIsSaved}
                          isOpenBookmark={isOpenBookmark}
                          refBookmark={refBookmark}
                          setCollectionLoading={setCollectionLoading}
                          trigger={
                            <Box
                              {...{
                                onMouseEnter: () => {
                                  setHoverCollections(true);
                                },
                                onMouseLeave: () => {
                                  setHoverCollections(false);
                                },
                                onBlur: () => {
                                  setHoverCollections(false);
                                },
                                borderRadius: "10px",
                              }}
                            >
                              <IconButton
                                aria-label="Bookmark"
                                variant={"secondaryBookmark"}
                                size="sm"
                                onClick={async () => {
                                  // openPopOver();
                                  setIsOpenBookmark(!isOpenBookmark);
                                }}
                                isLoading={collectionLoading}
                                cursor="pointer"
                                icon={
                                  <Flex
                                    {...{
                                      opacity: isSaved
                                        ? 1
                                        : hoverCollections
                                          ? 1
                                          : 0.65,
                                    }}
                                  >
                                    <Icon viewBox="0 0 17 17">
                                      {isSaved ? (
                                        <svg
                                          width="17"
                                          height="17"
                                          viewBox="0 0 17 17"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M9.32289 0.4375C9.19789 0.1875 8.94789 0 8.63539 0C8.35414 0 8.10414 0.1875 7.97914 0.4375L5.82289 4.84375L1.04164 5.5625C0.760388 5.59375 0.541638 5.8125 0.447888 6.0625C0.354138 6.34375 0.416638 6.625 0.635388 6.84375L4.10414 10.2812L3.26039 15.125C3.22914 15.4062 3.35414 15.7188 3.57289 15.875C3.82289 16.0312 4.10414 16.0625 4.35414 15.9375L8.63539 13.625L12.9166 15.9375C13.1666 16.0625 13.4791 16.0312 13.7291 15.875C13.9479 15.7188 14.0729 15.4062 14.0104 15.125L13.1979 10.2812L16.6666 6.84375C16.8854 6.625 16.9479 6.34375 16.8541 6.0625C16.7604 5.8125 16.5416 5.59375 16.2604 5.5625L11.4791 4.84375L9.32289 0.4375Z"
                                            fill="#ED8936"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          width="17"
                                          height="17"
                                          viewBox="0 0 17 17"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M8.63541 0C8.94791 0 9.19791 0.1875 9.32291 0.4375L11.4792 4.84375L16.2604 5.5625C16.5417 5.59375 16.7604 5.8125 16.8542 6.0625C16.9479 6.34375 16.8854 6.625 16.6667 6.84375L13.1979 10.2812L14.0104 15.125C14.0729 15.4062 13.9479 15.7188 13.7292 15.875C13.4792 16.0312 13.1667 16.0625 12.9167 15.9375L8.63541 13.625L4.35416 15.9375C4.10416 16.0625 3.82291 16.0312 3.57291 15.875C3.35416 15.7188 3.22916 15.4062 3.26041 15.125L4.10416 10.2812L0.635406 6.84375C0.416656 6.625 0.354156 6.34375 0.447906 6.0625C0.541656 5.8125 0.760406 5.59375 1.04166 5.5625L5.82291 4.84375L7.97916 0.4375C8.10416 0.1875 8.35416 0 8.63541 0ZM8.63541 2.46875L7.01041 5.875C6.88541 6.09375 6.69791 6.25 6.44791 6.28125L2.72916 6.8125L5.41666 9.46875C5.60416 9.65625 5.66666 9.90625 5.63541 10.1562L5.01041 13.875L8.29166 12.125C8.51041 12 8.79166 12 9.01041 12.125L12.2917 13.875L11.6667 10.1562C11.6042 9.90625 11.6979 9.65625 11.8854 9.46875L14.5417 6.8125L10.8542 6.28125C10.6042 6.25 10.3854 6.09375 10.2917 5.875L8.63541 2.46875Z"
                                            fill="#ED8936"
                                          />
                                        </svg>
                                      )}
                                    </Icon>
                                  </Flex>
                                }
                                mr={0}
                              />
                            </Box>
                          }
                        />
                      )}
                    </Box>
                    {/* </Tooltip> */}
                  </Stack>
                </>
              )}
            </Flex>
            <Divider mb={0.5} />
          </>
        )}
        {!disableCopy ? (
          <CopyToClipboard
            text={tweet.data.fullTextAppended}
            onCopy={() => {
              toast.success("Post copied to clipboard", {
                style: {
                  background: "gray.600",
                  color: "#222",
                },
              });
            }}
          >
            <StyledText
              textStyle={textStyle["body.medium.light"]}
              p={1}
              pl={3}
              pr={2}
              whiteSpace="pre-wrap"
              dangerouslySetInnerHTML={{
                __html: tweet.data.fullText,
              }}
            // sx={{
            //   'a': { color: 'colors.primary.lightMode.default'},
            // }}
            />
          </CopyToClipboard>
        ) : (
          <StyledText
            textStyle={textStyle["body.medium.light"]}
            p={3}
            pl={5}
            pr={4}
            whiteSpace="pre-wrap"
            dangerouslySetInnerHTML={{
              __html: tweet.data.fullText,
            }}
          />
        )}
        {tweet.data?.extended_entities?.media &&
          tweet.data?.extended_entities?.media.length > 0 && (
            <Box w="100%" display="flex">
              {tweet.data?.extended_entities?.media.map((media, index) => (
                <Box key={tweet.data.id_str + index}>
                  <Image
                    objectFit="cover"
                    // p={2}
                    src={media.media_url_https}
                  />
                </Box>
              ))}
            </Box>
          )}
        {!disableFooter && (
          <>
            <Divider my={2} />
            <Flex
              pt={0}
              pb={2}
              px={2}
              alignItems="center"
              justifyContent="space-between"
            >
              {!isVariation ? (
                <Box display="flex" flexDir="row">
                  {!disableLike && (
                    <Flex alignItems="center" mr={3} ml={3}>
                      {/* <Box mr={2} as={AiFillLike} color={theme.colors.blue[500]} /> */}
                      <Icon viewBox="0 0 16 15" mr={2}>
                        <svg
                          width="16"
                          height="15"
                          viewBox="0 0 16 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 5.5H1C0.4375 5.5 0 5.9375 0 6.46875V13.4375C0 13.9688 0.4375 14.4062 1 14.4062H3C3.53125 14.4062 4 13.9688 4 13.4375V6.5C4 5.96875 3.53125 5.5 3 5.5ZM16 6.59375C16 5.46875 15.0312 4.53125 13.9062 4.53125H10.7812C11.0625 3.75 11.25 3.0625 11.25 2.65625C11.25 1.59375 10.4062 0.53125 9.03125 0.53125C7.59375 0.53125 7.1875 1.53125 6.875 2.34375C5.875 4.875 5 4.40625 5 5.25C5 5.65625 5.3125 6 5.75 6C5.90625 6 6.0625 5.96875 6.1875 5.84375C8.59375 3.9375 8 2.03125 9.03125 2.03125C9.5625 2.03125 9.75 2.40625 9.75 2.65625C9.75 2.90625 9.5 3.90625 8.9375 4.90625C8.875 5.03125 8.84375 5.15625 8.84375 5.28125C8.84375 5.71875 9.1875 6 9.59375 6H13.875C14.2188 6.03125 14.5 6.28125 14.5 6.59375C14.5 6.90625 14.25 7.1875 13.9375 7.1875C13.5312 7.21875 13.2188 7.5625 13.2188 7.9375C13.2188 8.4375 13.5938 8.4375 13.5938 8.84375C13.5938 9.625 12.5 9.21875 12.5 10.1562C12.5 10.5 12.6875 10.5625 12.6875 10.8438C12.6875 11.5625 11.75 11.2812 11.75 12.125C11.75 12.2812 11.8125 12.3125 11.8125 12.4375C11.8125 12.75 11.5312 13.0312 11.2188 13.0312H9.5625C8.75 13.0312 7.96875 12.75 7.34375 12.2812L6.1875 11.4062C6.0625 11.3125 5.90625 11.25 5.75 11.25C5.3125 11.25 4.96875 11.625 4.96875 12C4.96875 12.25 5.09375 12.4688 5.28125 12.625L6.4375 13.4688C7.34375 14.1562 8.4375 14.5 9.5625 14.5H11.2188C12.3125 14.5 13.2188 13.6562 13.2812 12.5625C13.8438 12.1875 14.1875 11.5625 14.1875 10.8438C14.1875 10.75 14.1875 10.6562 14.1875 10.5625C14.7188 10.1875 15.0938 9.5625 15.0938 8.84375C15.0938 8.6875 15.0625 8.53125 15.0312 8.34375C15.5938 7.96875 16 7.34375 16 6.59375Z"
                            fill="#0A66C2"
                          />
                        </svg>
                      </Icon>
                      {numbro(
                        tweet?.data?.public_metrics?.like_count ??
                        tweet.data.favorite_count
                      )
                        ?.format({
                          // mantissa: 1,
                          average: true,
                          totalLength: 1,
                        })
                        .replace(".0", "")}
                    </Flex>
                  )}
                  {(tweet.data.retweet_count ||
                    tweet.data.retweet_count === 0) &&
                    !disableRetweet && (
                      <Flex
                        alignItems="center"
                        cursor="pointer"
                        onClick={() => {
                          //@ts-ignore
                          tweetContext.newTweet({
                            text: `https://twitter.com/${tweet.data.twUserName ??
                              tweet.data.user?.screen_name
                              }/status/${tweet.data.id_str}`,
                          });
                          //@ts-ignore
                          tweetContext.open();
                        }}
                      >
                        <Box
                          mr={2}
                          as={FaRetweet}
                          color={theme.colors.green[300]}
                        />
                        {numbro(
                          tweet?.data?.public_metrics
                            ? tweet.data.public_metrics.quote_count +
                            tweet.data.public_metrics.retweet_count
                            : tweet.data.retweet_count
                        )
                          ?.format({
                            // mantissa: 1,
                            average: true,
                            totalLength: 1,
                          })
                          .replace(".0", "")}
                      </Flex>
                    )}
                  {(tweet.data.commentCount ||
                    tweet.data.commentCount === 0) && (
                      <Flex alignItems="center">
                        <Box
                          mr={2}
                          as={FaComment}
                          color={theme.colors.green[300]}
                        />
                        {numbro(tweet.data.commentCount)
                          ?.format({
                            // mantissa: 1,
                            average: true,
                            totalLength: 1,
                          })
                          .replace(".0", "")}
                      </Flex>
                    )}
                  {(tweet.data.user.followers_count ||
                    tweet.data.user.followers_count === 0) && !disableFollowersCount && (
                      <Flex alignItems="center">
                        <Box
                          mr={2}
                          ml={2}
                          as={FiUser}
                          color={"#0A66C2"}
                          title={"Follower count"}
                        />
                        {numbro(tweet.data.user.followers_count)
                          ?.format({
                            // mantissa: 1,
                            average: true,
                            totalLength: 1,
                          })
                          .replace(".0", "")}
                      </Flex>
                    )}
                </Box>
              ) : (
                <Box></Box>
              )}
              <Stack
                isInline
                spacing={1}
                alignItems="center"
                width={showCopyToClipboard ? "100%" : "auto"}
                justifyContent="space-between"
              >
                {!disableMainHideButton && (
                  <Tooltip
                    label={
                      typeof window !== "undefined" &&
                        //@ts-ignore
                        window?.navigator?.userAgentData?.platform?.includes(
                          "mac"
                        )
                        ? "Ctrl-X"
                        : "Ctrl-X"
                    }
                  >
                    <Button
                      variant="outline"
                      size="sm"
                      _hover={{
                        colorScheme: "red",
                        bg: "red.500",
                        color: "white",
                      }}
                      leftIcon={<Icon as={CloseIcon} fontSize="xs" />}
                      onClick={async () => {
                        //@ts-ignore
                        removeTweet(tweet.data);
                        hideTweet(tweet.data, session?.user?.uid, -1);
                      }}
                    >
                      {"Hide"}
                    </Button>
                  </Tooltip>
                )}
                {showCopyToClipboard ? (
                  <CopyToClipboard
                    text={tweet.data.fullTextAppended}
                    onCopy={() => {
                      toast.success("Post copied to clipboard", {
                        style: {
                          background: "gray.600",
                          color: "#222",
                        },
                      });
                    }}
                  >
                    <Button variant="actionEditPost" leftIcon={<FiCopy />}>
                      Copy
                    </Button>
                  </CopyToClipboard>
                ) : (
                  false
                )}
                {mode == "answer" ? (
                  <></>
                ) : !disableEditTweet ? (
                  // <Tooltip label={session?.user?.uid ? "Open the scheduler and prefil with this tweet" : "You need to be signed up to tweet"}>
                  <Box>
                    {!disableAggreeDisagree && (
                      <>
                        <Button
                          mr={2}
                          variant="secondary"
                          isLoading={isLoadingAggree}
                          isDisabled={!session?.user?.uid}
                          size="sm"
                          onClick={async () => {
                            setIsLoadingAggree(true);
                            analytics.log('industry_news_article_generated');
                            let generation = await getAggreeDisaggree(
                              false,
                              tweet.data.full_text,
                              tweet.data.news,
                              session,
                              mainContext
                            );
                            setIsLoadingAggree(false);

                            if (generation) {
                              //@ts-ignore
                              tweetContext.newTweet({ text: generation });
                              // @ts-ignore
                              tweetContext.setIsTweetTextChanged(true);
                              //@ts-ignore
                              tweetContext.open();
                            }
                          }}
                        >
                          {"Disagree"}
                        </Button>
                        <Button
                          mr={2}
                          variant="secondary"
                          isLoading={isLoadingAggree}
                          isDisabled={!session?.user?.uid}
                          size="sm"
                          onClick={async () => {
                            setIsLoadingAggree(true);
                            analytics.log('industry_news_article_generated');
                            let generation = await getAggreeDisaggree(
                              true,
                              tweet.data.full_text,
                              tweet.data.news,
                              session,
                              mainContext
                            );
                            setIsLoadingAggree(false);
                            console.log({ generation });

                            if (generation) {
                              //@ts-ignore
                              tweetContext.newTweet({ text: generation });
                              // @ts-ignore
                              tweetContext.setIsTweetTextChanged(true);
                              //@ts-ignore
                              tweetContext.open();
                            } else {
                              console.log("fail to generate agree");
                            }
                          }}
                        >
                          {"Agree"}
                        </Button>
                      </>
                    )}
                    <Button
                      variant={
                        source === "suggested" ? "actionEditPost" : "secondary"
                      }
                      isDisabled={!session?.user?.uid}
                      onClick={handleAddToComposer}
                      size="sm"
                    >
                      {addToComposerBtnText || "Edit & post"}
                    </Button>
                  </Box>
                ) : (
                  // </Tooltip>
                  <></>
                )}
                {!disableAddToQueue && (
                  <Box>
                    <Button
                      variant={addedToQueue ? "invisible" : "secondary"}
                      isLoading={isLoadingAddToQueue}
                      ml={1}
                      size="sm"
                      onClick={async () => {

                        let newId = uuidv4();

                        setIsLoadingAddToQueue(true);
                        await tweetContext.createDraft(
                          tweet.data.full_text,
                          newId
                        );

                        let dataToSend = {
                          idUser: getAccount(session)?.id,
                          //@ts-ignore
                          id: newId,
                          //@ts-ignore
                          text: tweet.data.full_text,
                          isScheduled: true,
                          //@ts-ignore
                          scheduledTime: tweetContext.nextSlot,
                          isAutoPlug: false,
                          // autoPlugHours: getAccount(session)?.autoPlugHours ?? 1,
                          // isPluggedTimeBased: true,
                          // autoPlugText: getAccount(session)?.autoPlugText ?? "",
                          isRetweeted: false,
                          isPlugged: false,
                          isInfluentLeaders: true,
                          isPostTwitter: false,
                          isPostLinkedin: true,
                        };

                        if (
                          !(await checkTweetValid(
                            [],
                            session,
                            tweetContext,
                            dataToSend,
                            undefined
                          ))
                        ) {
                          setIsLoadingAddToQueue(false);
                          return;
                        }

                        setAddedToQueue(true);
                        setIsLoadingAddToQueue(false);

                        updateOnboardSteps("schedule", "engage");
                        await scheduleTweet(
                          undefined,
                          undefined,
                          dataToSend,
                          getAccount(session)?.timezone,
                          tweetContext
                        );
                        //@ts-ignore
                        tweetContext.refreshNextSlot();
                      }}
                      leftIcon={
                        addedToQueue ? (
                          <Icon viewBox="0 0 15 11">
                            <svg
                              width="15"
                              height="11"
                              viewBox="0 0 15 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.9166 0.71875C14.2291 1.03125 14.2291 1.5 13.9166 1.78125L5.66663 10.0312C5.38538 10.3438 4.91663 10.3438 4.63538 10.0312L0.385376 5.78125C0.072876 5.5 0.072876 5.03125 0.385376 4.71875C0.666626 4.4375 1.13538 4.4375 1.41663 4.71875L5.16663 8.46875L12.8854 0.71875C13.1666 0.4375 13.6354 0.4375 13.9166 0.71875Z"
                                fill="#31C52E"
                              />
                            </svg>
                          </Icon>
                        ) : (
                          <></>
                        )
                      }
                    >
                      <Text
                        color={
                          addedToQueue
                            ? color["success.lightMode.default"]
                            : undefined
                        }
                        _dark={{
                          color: addedToQueue
                            ? color["success.darkMode.default"]
                            : undefined,
                        }}
                      >
                        {addedToQueue ? "Added" : "Add to Queue"}
                      </Text>
                    </Button>
                  </Box>
                )}
                {!disableCheckmark && (
                  <>
                    <IconButton
                      aria-label="checkmark"
                      color={isSelected ? "green.300" : "gray.200"}
                      variant="ghost"
                      size="lg"
                      height="30px"
                      colorScheme={isSaved ? "gray" : "gray"}
                      icon={<BsCheckCircle fontSize="24px" />}
                    />
                  </>
                )}
                {!disableSubMenu && (
                  <Box position="relative">
                    <IconButton
                      aria-label="More"
                      // variant="ghost"
                      variant="secondary"
                      size="sm"
                      icon={<FiMoreHorizontal fontSize="12px" />}
                      mr={-1}
                      onClick={() => {
                        setIsOpenSubMenu(!isOpenSubMenu);
                      }}
                    />
                    {isOpenSubMenu && (
                      <Box
                        position="absolute"
                        top="40px"
                        right="0"
                        bg={white}
                        boxShadow="rgba(100, 100, 111, 0.15) 0px 2px 19px 0px;"
                        rounded="md"
                        zIndex="1"
                        ref={refSubMenu}
                      >
                        <Stack
                          p={2}
                          justifyContent="left"
                          textAlign="left"
                          justifySelf="left"
                        >
                          <CopyToClipboard
                            text={tweet.data.fullTextAppended}
                            onCopy={() => {
                              // 
                              toast.success("Post copied to clipboard", {
                                style: {
                                  background: "gray.600",
                                  color: "#222",
                                },
                              });
                            }}
                          >
                            <Button
                              variant="action"
                              leftIcon={<BiCopy />}
                              onClick={() => {
                                setIsOpenSubMenu(false);
                              }}
                              justifyContent="left"
                            >
                              Copy / Paste
                            </Button>
                          </CopyToClipboard>
                          {!disableHideButton &&
                            removeTweet &&
                            !isVariation && (
                              <Button
                                variant="action"
                                leftIcon={<ViewOffIcon />}
                                onClick={async () => {
                                  //@ts-ignore
                                  removeTweet(tweet.data);
                                  await hideTweet(
                                    tweet.data,
                                    session?.user?.uid,
                                    -1
                                  );
                                  setIsOpenSubMenu(false);
                                }}
                                justifyContent="left"
                              >
                                Hide this post
                              </Button>
                            )}
                          {setExcludedUsers && (
                            <Button
                              justifyContent="left"
                              variant="action"
                              aria-label="Remove this user from appearing"
                              leftIcon={<AiOutlineMinusCircle />}
                              onClick={handleOnClickExcludeUser}
                            >
                              Exclude User
                            </Button>
                          )}
                          {
                            // session?.user?.data?.isAdmin && (
                            isAuthenticated && !disableTranslate && (
                              <Box display="flex" alignItems="center">
                                <Button
                                  // w="200px"
                                  variant="action"
                                  leftIcon={<SiGoogletranslate />}
                                  isLoading={isLoadingTrans}
                                  onClick={async () => {
                                    setIsLoadingTrans(true);
                                    const token = await getToken(session, "translate");
                                    let response = await fetch(
                                      "/api/translate",
                                      {
                                        method: "POST",
                                        headers: {
                                          "Content-Type":
                                            "application/json",
                                        },
                                        body: JSON.stringify({
                                          post: tweet.data.full_text,
                                          languageTo: languageTrans,
                                          token,
                                          tokenUserId: session?.user?.uid
                                        })
                                      }
                                    );
                                    setIsLoadingTrans(false);
                                    let data = await response.json();
                                    if (data.translation) {
                                      //@ts-ignore
                                      tweetContext.newTweet({
                                        text: data.translation,
                                      });
                                      //@ts-ignore
                                      tweetContext.setSelectedTweet(tweet.data);
                                      //@ts-ignore
                                      tweetContext.open();
                                      toast.success("Translation successful");
                                    } else {
                                      toast.error("Translation failed", {
                                        style: {
                                          background: "gray.600",
                                          color: "#222",
                                        },
                                      });
                                    }
                                  }}
                                  justifyContent="left"
                                >
                                  Translate
                                </Button>
                                <Select
                                  w="50px"
                                  h="100%"
                                  ml={5}
                                  variant="unstyled"
                                  size="sm"
                                  value={languageTrans}
                                  onChange={async (e) => {
                                    e.stopPropagation();
                                    setLanguageTrans(e.target.value);
                                    if (session?.user?.data) {
                                      const db = firebaseClient.firestore();
                                      await db
                                        .collection("users")
                                        .doc(session?.user?.uid)
                                        .update({ language: e.target.value });
                                      session.user.data.language =
                                        e.target.value;
                                    }
                                  }}
                                >
                                  <option value="es">🇪🇸</option>
                                  <option value="fr">🇫🇷</option>
                                  <option value="de">🇩🇪</option>
                                  <option value="it">🇮🇹</option>
                                  <option value="ru">🇷🇺</option>
                                  <option value="id">🇮🇩</option>
                                  <option value="tr">🇹🇷</option>
                                </Select>
                              </Box>
                            )
                          }
                          {session?.user?.data?.isAdmin && (
                            <Button
                              variant="action"
                              leftIcon={<AiFillEye />}
                              onClick={async () => {
                                console.log(tweet.data);
                                toast.success("Tweet pasted in the JS console");
                              }}
                              justifyContent="left"
                            >
                              Debug Tweet (admin)
                            </Button>
                          )}
                          {/* // here */}
                          {!disableRequest && (
                            <Button
                              variant="action"
                              leftIcon={
                                <Icon viewBox="0 0 13 16">
                                  <defs>
                                    <linearGradient
                                      id="Gradient"
                                      x1="0"
                                      x2="0"
                                      y1="0"
                                      y2="1"
                                    >
                                      <stop
                                        offset="20%"
                                        stopColor={"#e86d8a"}
                                      />
                                      <stop
                                        offset="80%"
                                        stopColor={"#527ff0"}
                                      />
                                    </linearGradient>
                                  </defs>
                                  <path
                                    d="M9.69791 0.03125C10.2917 0.03125 10.7292 0.59375 10.5729 1.15625L8.88541 7H11.5729C12.1667 7 12.6667 7.5 12.6667 8.09375C12.6667 8.4375 12.5104 8.71875 12.2604 8.9375L4.16666 15.8125C4.01041 15.9375 3.82291 16 3.60416 16C3.01041 16 2.57291 15.4375 2.72916 14.875L4.41666 9H1.69791C1.13541 9 0.666656 8.53125 0.666656 7.96875C0.666656 7.65625 0.791656 7.34375 1.01041 7.15625L9.13541 0.21875C9.29166 0.09375 9.47916 0 9.69791 0V0.03125ZM8.57291 2.65625L2.91666 7.5H5.41666C5.63541 7.5 5.85416 7.625 6.01041 7.8125C6.13541 8 6.19791 8.25 6.13541 8.46875L4.72916 13.375L10.4479 8.5H7.91666C7.66666 8.5 7.44791 8.40625 7.29166 8.21875C7.16666 8.03125 7.10416 7.78125 7.16666 7.5625L8.57291 2.65625Z"
                                    fill="url(#Gradient)"
                                  />
                                </Icon>
                              }
                              onClick={handleVariations}
                              justifyContent="left"
                            >
                              Generate variations
                            </Button>
                          )}
                          {!disableBookmarkButton && (
                            <Button
                              onClick={async () => {
                                setIsOpenBookmark(!isOpenBookmark);
                                // onClose();
                                // closePopOver()
                                // setIsOpenBookmark(false);
                                setIsOpenSubMenu(!isOpenSubMenu);
                              }}
                              variant="action"
                              leftIcon={
                                <Icon viewBox="0 0 17 17">
                                  {isSaved ? (
                                    <svg
                                      width="17"
                                      height="17"
                                      viewBox="0 0 17 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9.32289 0.4375C9.19789 0.1875 8.94789 0 8.63539 0C8.35414 0 8.10414 0.1875 7.97914 0.4375L5.82289 4.84375L1.04164 5.5625C0.760388 5.59375 0.541638 5.8125 0.447888 6.0625C0.354138 6.34375 0.416638 6.625 0.635388 6.84375L4.10414 10.2812L3.26039 15.125C3.22914 15.4062 3.35414 15.7188 3.57289 15.875C3.82289 16.0312 4.10414 16.0625 4.35414 15.9375L8.63539 13.625L12.9166 15.9375C13.1666 16.0625 13.4791 16.0312 13.7291 15.875C13.9479 15.7188 14.0729 15.4062 14.0104 15.125L13.1979 10.2812L16.6666 6.84375C16.8854 6.625 16.9479 6.34375 16.8541 6.0625C16.7604 5.8125 16.5416 5.59375 16.2604 5.5625L11.4791 4.84375L9.32289 0.4375Z"
                                        fill="#ED8936"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      width="17"
                                      height="17"
                                      viewBox="0 0 17 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.63541 0C8.94791 0 9.19791 0.1875 9.32291 0.4375L11.4792 4.84375L16.2604 5.5625C16.5417 5.59375 16.7604 5.8125 16.8542 6.0625C16.9479 6.34375 16.8854 6.625 16.6667 6.84375L13.1979 10.2812L14.0104 15.125C14.0729 15.4062 13.9479 15.7188 13.7292 15.875C13.4792 16.0312 13.1667 16.0625 12.9167 15.9375L8.63541 13.625L4.35416 15.9375C4.10416 16.0625 3.82291 16.0312 3.57291 15.875C3.35416 15.7188 3.22916 15.4062 3.26041 15.125L4.10416 10.2812L0.635406 6.84375C0.416656 6.625 0.354156 6.34375 0.447906 6.0625C0.541656 5.8125 0.760406 5.59375 1.04166 5.5625L5.82291 4.84375L7.97916 0.4375C8.10416 0.1875 8.35416 0 8.63541 0ZM8.63541 2.46875L7.01041 5.875C6.88541 6.09375 6.69791 6.25 6.44791 6.28125L2.72916 6.8125L5.41666 9.46875C5.60416 9.65625 5.66666 9.90625 5.63541 10.1562L5.01041 13.875L8.29166 12.125C8.51041 12 8.79166 12 9.01041 12.125L12.2917 13.875L11.6667 10.1562C11.6042 9.90625 11.6979 9.65625 11.8854 9.46875L14.5417 6.8125L10.8542 6.28125C10.6042 6.25 10.3854 6.09375 10.2917 5.875L8.63541 2.46875Z"
                                        fill="#ED8936"
                                      />
                                    </svg>
                                  )}
                                </Icon>
                              }
                              justifyContent="left"
                            >
                              {isSaved
                                ? "Edit collection"
                                : "Save to collection"}
                            </Button>
                          )}
                          {!isVariation && (
                            <Button
                              variant="actionDestructive"
                              leftIcon={<CgDanger />}
                              onClick={async () => {
                                postData({
                                  url: "user-reportTweet",
                                  data: { tweet: tweet.data },
                                  session,
                                  token: "",
                                });
                                toast.success(
                                  "Tweet reported, we will have a look"
                                );
                              }}
                              justifyContent="left"
                            >
                              Report post
                            </Button>
                          )}
                        </Stack>
                      </Box>
                    )}
                  </Box>
                )}
              </Stack>
            </Flex>
          </>
        )}
      </Box>
      <ConfirmPopup
        isOpen={isOpen}
        onClose={onClose}
        title="Information"
        body={
          "It seems you already have content in the post composer.\nAre you sure you want to replace it with this hook?"
        }
        allowClickBackground={true}
        cta="Yes, replace the content"
        showTitleInfoIcon={true}
        // @ts-ignore
        customAlternateAction={({ callback }) => (
          <CopyToClipboard
            text={tweet.data.fullTextAppended}
            onCopy={() => {
              toast.success("Post copied to clipboard", {
                style: {
                  background: "gray.600",
                  color: "#222",
                },
              });
              callback && callback();
            }}
          >
            <Button variant="tertiary" leftIcon={<FiCopy />}>
              Copy to clipboard
            </Button>
          </CopyToClipboard>
        )}
        callback={() => {
          addTextToComposer();
        }}
      />
    </Box>
  );
};

export default Tweet;
