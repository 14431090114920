import { popoverAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(popoverAnatomy.keys);

const baseStyle = definePartsStyle({
  content: {
    border: "1px solid",
    borderColor: "border.lightMode.light",
    borderRadius: "10px",
    bg: "white",
    _dark: {
      borderColor: "border.darkMode.light",
      bg: "#1E1E1E",
    },
  },
  header: {
    px: 4,
    py: 3,
    borderBottomWidth: "1px",
    borderColor: "border.lightMode.light",
    _dark: {
      borderColor: "border.darkMode.light",
    },
  },
  body: {
    px: 4,
    py: 3,
  },
  footer: {
    px: 4,
    py: 3,
    borderTopWidth: "1px",
    borderColor: "border.lightMode.light",
    _dark: {
      borderColor: "border.darkMode.light",
    },
  },
  arrow: {
    bg: "white",
    borderColor: "border.lightMode.light",
    _dark: {
      bg: "#1E1E1E",
      borderColor: "border.darkMode.light",
    },
  },
});

// export the component theme
export const Popover = defineMultiStyleConfig({
  baseStyle,
});
